<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <div>收货单基本信息</div>
    </el-row>
    <el-form label-width="102px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="收货单号：">{{ DetailsDatas.receiptId }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号：">{{ DetailsDatas.orderId }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库类型：">{{ getDepositLabel(DetailsDatas.depositType) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库仓库：">{{ DetailsDatas.logicWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库方式：">{{ getReceiveTypeLabel(DetailsDatas.receiveType) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="应收数量：">{{ dataObj.expectPairs }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="已收数量：">{{ dataObj.actualPairs }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间：">{{ dataObj.createTime }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <el-row class="grid-content bg-purple-dark">
      <div>收货明细</div>
    </el-row>
    <el-table ref="multipleTable" class="mb-3" :data="ShippingInfoable" :header-cell-style="{background:'#fafafa'}" max-height="500px">
      <el-table-column
        type="index"
        :label="$t('page.No')"
        align="center"
      />
      <el-table-column
        prop="platformCode"
        label="平台"
        align="center"
      />
      <el-table-column
        prop="siteCode"
        label="站点"
        align="center"
      />
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />
      <el-table-column
        prop="fnsku"
        label="FNSKU"
        align="center"
      />
      <el-table-column
        prop="upc"
        label="UPC"
        align="center"
      />
      <el-table-column
        prop="style,color,size"
        :label="$t('page.Specifications')"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column
        prop="expectPairs"
        label="应收数量"
        align="center"
      />
      <el-table-column
        prop="actualPairs"
        label="收货数量"
        align="center"
      />
    </el-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>

</template>

<script>
import { findTaskId } from '@/api/inventory-result'
import { findCollectDetail } from '@/api/stockin'
import { Mixin } from '@/mixin/mixin.js'
export default {
  mixins: [Mixin],
  data() {
    return {
      dataObj: { tatolPairs: '', inboundTime: '' },
      DetailsDatas: {},
      ShippingInfoable: []
    }
  },

  mounted() {
    const { receiptData } = this.$route.query
    this.DetailsDatas = receiptData && JSON.parse(receiptData) || {}
    if (!Object.keys(this.DetailsDatas).length && !this.$route.query.abnormalType) {
      return
    }
    if (this.$route.query.abnormalType === 'IN') {
      this._findTaskId(this.$route.query)
    } else {
      this._findCollectDetail(this.DetailsDatas)
    }
  },
  methods: {
    // 获取详情数据
    async _findCollectDetail() {
      const { receiptId, tableSuffix } = this.$route.query
      const data = { receiptId, tableSuffix }
      const { datas } = await findCollectDetail(data)
      this.ShippingInfoable = datas.ttd ? datas.ttd : []
      const { actualPairs, createTime, expectPairs } = datas
      this.dataObj = { actualPairs, createTime, expectPairs }
      // if (this.DetailsDatas.transportStatus) {
    },
    // 获取详情数据
    async _findTaskId(data) {
      const { taskId, abnormalType, handleCode } = data
      const params = { taskId, abnormalType, handleCode }
      const { datas } = await findTaskId(params)
      this.DetailsDatas = datas && datas.collectTask || {}
      const { actualPairs, createTime, expectPairs } = datas && datas.collectTask || {}
      this.dataObj = { actualPairs, createTime, expectPairs }
      this.ShippingInfoable = datas && datas.collectTaskDetailList || []
    },
    cancle() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
